<template>
  <div class="pagination">
    <div class="pagination__container">
      <div class="pagination__prev-page" v-if="page !== 1" @click="handlePageChange(page - 1)">
        <div></div>
      </div>
      <span
        v-for="currentPage in pages"
        :key="currentPage"
        :class="[currentPage === page && 'active', currentPage === null && 'null']"
        @click="handlePageChange(currentPage)"
      >
        {{ currentPage }}
      </span>
      <div
        class="pagination__next-page"
        v-if="page !== maxPage"
        @click="handlePageChange(page + 1)"
      >
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    maxPage: {
      type: [Number, String],
      default: 0,
    },
    page: {
      type: [Number, String],
      default: 0,
    },
    handlePageChange: {
      type: Function,
      default() {
        return [];
      },
    },
  },
  computed: {
    pages() {
      const pagesArray = [
        this.page != 2 && this.page != 1 ? this.page - 2 : null,
        this.page != 1 ? this.page - 1 : null,
        this.page,
        this.page != this.maxPage ? this.page + 1 : null,
        this.page != this.maxPage - 1 && this.page != this.maxPage ? this.page + 2 : null,
      ];
      return pagesArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  &__container {
    display: flex;
    align-items: center;
  }

  span {
    font-size: $g-font-small-size;
    margin: 0 10px;
    cursor: pointer;
  }

  span.null {
    margin: 0;
  }

  span.active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: $mainSecondColor;
    font-size: $g-font-small-size;
    color: $mainColor;
    pointer-events: none;
  }

  &__prev-page {
    display: inline-block;
    background: $mainColor;
    opacity: 0.85;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      background: $mainSecondColor;
    }
    div {
      background-image: url("../../assets/images/icons/slick-prev.png");
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &__next-page {
    display: inline-block;
    background: $mainColor;
    opacity: 0.85;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      background: $mainSecondColor;
    }
    div {
      background-image: url("../../assets/images/icons/slick-next.png");
      height: 30px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>
