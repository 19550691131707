<template>
  <Main>
    <div class="drivers">
      <div class="drivers__container">
        <h1 class="drivers__title">Водители</h1>
        <div class="drivers__header">
          <div class="drivers__toggle-moderated">
            <div
              :class="['drivers__toggle-item', moderated === 1 ? 'active' : '']"
              @click="handleModerated(1)"
            >
              В работе
            </div>
            <div
              :class="['drivers__toggle-item', moderated === 0 ? 'active' : '']"
              @click="handleModerated(0)"
            >
              На модерации
              <span v-if="notificationDriverModerated != 0" class="drivers__no-read"></span>
            </div>
            <div
              :class="[
                'drivers__toggle-item',
                moderated === null && moderatedAfterEdit ? 'active' : '',
              ]"
              @click="handleModeratedAfterEdit"
            >
              Повторная модерация
              <span
                v-if="notificationDriverModeratedAfterEdit != 0"
                class="drivers__no-read"
              ></span>
            </div>
            <div
              :class="['drivers__toggle-item', moderated === null && deleted ? 'active' : '']"
              @click="handleDeleted"
            >
              Удаленные
            </div>
          </div>
          <div class="drivers__tools">
            <InputSearch
              :label="'search'"
              :search="searchDrivers"
              :placeholder="'Поиск по имени водителя'"
            />
            <ButtonMain @click.native="handleCreateDriver">Создать водителя</ButtonMain>
          </div>
        </div>
        <CustomTable
          v-if="driversList.length"
          :data="driversList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :on-custom-style-column="tableOptions.onCustomStyleColumn"
          :handle-delete="handleDeleteDriver"
          :if-handle-delete="!deleted"
          :handleFilter="handleFilter"
          :handle-copy="handleModelCopy"
          :handle-edit="toUpdateDriver"
          :handle-restore="handleOpenRestoreModal"
          :if-handle-restore="deleted"
          :direction="directionSort"
          :order="orderSort"
          :name="'driver'"
        >
          <template v-slot:footer>
            <div class="pagination">
              <Pagination
                :maxPage="drivers.meta.lastPage"
                :page="page"
                :handlePageChange="handlePageChange"
              />
            </div>
          </template>
        </CustomTable>
        <div v-else class="drivers__none">Водители не найдены!</div>
      </div>
    </div>
    <DefaultModal
      class="drivers__confirm-modal"
      v-if="deleteModal"
      :on-close="handleCloseConfirmModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleDelete">
        <template v-slot:description>
          <p>Вы уверены, что хотите удалить водителя?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      class="drivers__confirm-modal"
      v-if="restoreModal"
      :on-close="handleCloseRestoreModal"
    >
      <ConfirmModalContent :on-cancel="handleCloseRestoreModal" :on-success="handleRestore">
        <template v-slot:description>
          <p>Вы уверены, что хотите восстановить водителя?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "../constants/routes";

import Main from "../templets/Main.vue";
import CustomTable from "../components/molecules/CustomTable.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import Pagination from "../components/molecules/Pagination.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import InputSearch from "../components/atoms/inputs/InputSearch.vue";

export default {
  name: "Drivers",
  data() {
    return {
      deleteModal: false,
      rowToDelete: null,
      directionSort: false,
      orderSort: "id",
      moderatedAfterEdit: false,
      deleted: false,
      restoreModal: false,
      rowToRestore: null,
      search: null,
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        onCustomStyleColumn: [
          {
            columnName: "last_name",
            className: "clip",
          },
        ],
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "last_name",
            to: "ФИО",
          },
          {
            from: "phone",
            to: "Телефон",
          },
          {
            from: "drivers_license_series",
            to: "Удостоверение",
          },
          {
            from: "type_of_car_id",
            to: "Тип авто",
          },
          {
            from: "body_type_id",
            to: "Тип кузова",
          },
          {
            from: "brand_id",
            to: "Марка",
          },
          {
            from: "model",
            to: "Модель",
          },
          {
            from: "car_number",
            to: "Номер авто",
          },
          {
            from: "year_of_car_manufacture",
            to: "Год выпуска",
          },
          {
            from: "trailer_number",
            to: "Номер прицепа",
          },
          {
            from: "register_at",
            to: "Дата регистрации",
          },
          {
            from: "free",
            to: "Статус",
          },
        ],
        customOrder: [
          "id",
          "last_name",
          "phone",
          "drivers_license_series",
          "type_of_car_id",
          "body_type_id",
          "brand_id",
          "model",
          "car_number",
          "year_of_car_manufacture",
          "trailer_number",
          "register_at",
          "free",
          "null",
        ],
      },
    };
  },
  components: {
    Main,
    CustomTable,
    FetchSpinnerModal,
    DefaultModal,
    ConfirmModalContent,
    Pagination,
    ButtonMain,
    InputSearch,
  },
  computed: {
    ...mapState("drivers", ["error", "drivers", "driversList", "loadingProcess", "moderated"]),
    ...mapState("notification", [
      "notificationAll",
      "notificationDriverModerated",
      "notificationDriverModeratedAfterEdit",
    ]),
    page() {
      return +this.$route.params.page;
    },
  },
  mounted() {
    this.getDriversList();
  },
  methods: {
    handlePageChange(page) {
      this.$router.push({
        path: ROUTE.DRIVERS_PAGE.replace(":page", page),
      });
      this.getDriversList();
    },
    handleCreateDriver() {
      this.$router.push({
        path: ROUTE.DRIVER_CREATE_PAGE,
      });
    },
    getDriversList() {
      let url = `?page=${this.page}`;

      if (this.moderated == null) {
        if (this.moderatedAfterEdit) {
          url = url + `&moderated_after_edit=0`;
        }
        if (this.deleted) {
          url = url + `&deleted=1`;
        }
      } else {
        url = url + `&moderated=${this.moderated}`;
      }

      if (this.search != null && this.search != "") {
        url = url + `&search=${this.search}`;
      }

      if (this.orderSort.length) {
        url = url + `&order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("drivers/getDriversList", url).finally(() => {});
    },
    handleModelCopy(row) {
      this.$router.push({
        path: ROUTE.DRIVER_DETAIL_PAGE.replace(":driverId", row.id),
      });
    },
    handleDeleteDriver(row) {
      this.rowToDelete = row;
      this.deleteModal = true;
    },
    handleDelete() {
      const url = `/${this.rowToDelete.id}`;
      this.$store.dispatch("drivers/deleteDriver", url).finally(() => {
        this.getDriversList();
      });
      this.handleCloseConfirmModal();
    },
    handleCloseConfirmModal() {
      this.rowToDelete = null;
      this.deleteModal = false;
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getDriversList();
    },
    toUpdateDriver(row) {
      this.$router.push({
        path: ROUTE.DRIVER_CREATE_PAGE.replace(":process", "update").replace(":driverId", row.id),
      });
    },
    handleModerated(mod) {
      this.$store.commit("drivers/setModerated", mod);
      this.moderatedAfterEdit = false;
      this.deleted = false;
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      }
      if (mod == 0) {
        this.$store.dispatch("notification/notificationViewed", { types: ["driver_moderated"] });
      }
      setTimeout(() => {
        this.$store.commit("notification/setNotificationDriverModerated", 0);
      }, 1000);

      this.getDriversList();
    },
    handleModeratedAfterEdit() {
      this.$store.commit("drivers/setModerated", null);
      this.moderatedAfterEdit = true;
      this.deleted = false;
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      }
      setTimeout(() => {
        this.$store.commit("notification/setNotificationDriverModeratedAfterEdit", 0);
      }, 1000);
      this.$store.dispatch("notification/notificationViewed", {
        types: ["driver_moderated_after_edit"],
      });
      this.getDriversList();
    },
    handleDeleted() {
      this.$store.commit("drivers/setModerated", null);
      this.deleted = true;
      this.moderatedAfterEdit = false;
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      }
      this.getDriversList();
    },
    handleOpenRestoreModal(row) {
      this.restoreModal = true;
      this.rowToRestore = row;
    },
    handleCloseRestoreModal() {
      this.restoreModal = false;
      this.rowToRestore = null;
    },
    handleRestore() {
      const url = `/${this.rowToRestore.id}`;
      this.$store.dispatch("drivers/restoreDriver", url).finally(() => {
        this.getDriversList();
      });
      this.handleCloseRestoreModal();
    },
    searchDrivers(data) {
      if (this.page != 1) {
        this.$router.push({
          path: ROUTE.DRIVERS_PAGE.replace(":page", 1),
        });
      }
      this.search = data;
      this.getDriversList();
    },
  },
};
</script>

<style lang="scss" scoped>
.drivers {
  width: 100%;
  padding-top: 25px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &__container {
    margin: 0 20px;
  }

  &__toggle-moderated {
    @include flex-row;

    justify-content: space-between;
    overflow: hidden;
    width: 700px;
    height: 48px;
    border-radius: 5px;
  }

  &__none {
    font-size: 28px;
    color: #7d7d7d;
    padding-top: 30px;
  }

  &__tools {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding-left: 25px;

    /deep/.button-main {
      margin-left: 10px;
      width: 158px;
    }

    /deep/.search-input {
      flex-grow: 1;
      max-width: 300px;
    }
  }

  &__toggle-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(25% - 2px);
    height: 100%;
    background: $mainDisabled;
    font-size: 14px;
    font-weight: 500;
    color: $mainColor;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &.active {
      background: $mainColor;
      color: $g-color-white;
    }
  }

  &__no-read {
    position: absolute;
    width: 10px;
    height: 10px;
    background: #eb5959;
    border-radius: 50px;
    top: 5px;
    right: 5px;
  }
  /deep/.custom-table__header {
    grid-template-columns: 5% 8% 8% 12% 7% 7% 6% 7% 7% 7% 7% 7% 7% 5% !important;
  }
  /deep/.custom-table__row {
    grid-template-columns: 5% 8% 8% 12% 7% 7% 6% 7% 7% 7% 7% 7% 7% 5% !important;
  }
  /deep/.clip div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /deep/.button-main {
    height: 48px;
  }
  /deep/.search-input input {
    height: 48px;
  }
}
</style>
